import React from "react";

export default function Input({
  label,
  style,
  placeholder,
  register,
  error,
  name,
  containerStyle,
  type,
  ...rest
}) {
  return (
    <>
      <div className="mt-1" style={containerStyle}>
        <label className="form-label fw-bold">{label}</label>
        <input
          className="form-control "
          type={type}
          placeholder={placeholder}
          aria-label=".form-control example"
          style={style}
          {...(register && register(name))} //conditionally render props(if register is avilable then name will be register)
          {...rest}
        ></input>
        {error && <span className="text-error">{error}</span>}
      </div>
    </>
  );
}
