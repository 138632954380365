import React from 'react'
import TableHeader from './TableHeader'
import TableBody from './TableBody'

export default function Table({ keyFiled, columns, data, onSort, sortColumn, message }) {
    return (
        <>
            <div className="table-responsive">
                <table className="table caption-top table-hover bg-white" style={{ borderRadius: "4px" }}>
                    <TableHeader columns={columns} onSort={onSort} sortColumn={sortColumn} />
                    <TableBody
                        data={data}
                        keyFiled={keyFiled}
                        columns={columns}
                        message={message}
                    />
                </table>
            </div>
        </>
    )
}
