import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import avtar from "../assets/profile.svg";
import MyModal from "../components/MyModal";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { addJob } from "../../firebase/firebase";
import Input from "../components/Input";
import TextArea from "../components/TextArea";

const schema = yup.object({
  title: yup
    .string()
    .label("Title")
    .min(3)
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .required(),
  position: yup
    .number()
    .min(1, "Minimum value 1")
    .typeError("Please enter number")
    .required(),
  Jobtype: yup.string().label("Type").min(3).required(),
  experience: yup.string().label("Experience").min(2).required(),
  location: yup.string().label("Location").min(3).required(),
  skils: yup
    .array(
      yup.object({
        id: yup.number(),
        value: yup.string().label("Skils").required(),
      })
    )
    .min(1, "at least one skil is required")
    .required("required"),
  responsibilities: yup
    .array(
      yup.object({
        id: yup.number(),
        value: yup.string().label("Responsibility").required(),
      })
    )
    .min(1, "at least one responsilibility is required")
    .required("required"),
  qualification: yup
    .array(
      yup.object({
        id: yup.number(),
        value: yup.string().label("Qualification").required(),
      })
    )
    .min(1, "at least one qualification is required")
    .required("required"),
});

export default function AddNewJob() {
  const [show, setshow] = useState(false);
  const [loading, setLoading] = useState();
  let navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const skillFileds = useFieldArray({ name: "skils", control: control });

  const responsibility = useFieldArray({
    name: "responsibilities",
    control: control,
  });

  const qualifications = useFieldArray({
    name: "qualification",
    control: control,
  });

  useEffect(() => {
    console.log(errors);
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    const skils = [];
    data.skils?.map((skil) => {
      skils.push(skil.value);
    });
    data.skils = skils;

    const responsibilities = [];
    data.responsibilities?.map((responsibilitiy) => {
      responsibilities.push(responsibilitiy.value);
    });
    data.responsibilities = responsibilities;

    const qualifications = [];
    data.qualification?.map((q) => {
      qualifications.push(q.value);
    });
    data.qualification = qualifications;

    addJob(data).then((result) => {
      // navigate("/Job", { replace: true });
      setLoading(false);
      setshow(true);
    });
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);
  return (
    <>
      <SideBar />
      <MyModal
        show={show}
        title="Add Job Item"
        data="Added Job Successfully"
        handleClose={() => {
          navigate("/admin/jobs");
          setshow(false);
        }}
        centered
      />
      <div className="admin-container">
        <div className="top-detail">
          <div className="d-flex align-items-center">
            <img src={avtar} alt="" />
            <div>
              <h5 className="m-0">Welcome to,Jobs !</h5>
              <p className="m-0">Add New Job.</p>
            </div>
          </div>
        </div>
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)} className="p-3">
            <div className="row">
              <div className="col-md-6  mb-3 mt-3">
                <Input
                  label="Job Title:"
                  placeholder="Opening For ...."
                  type="text"
                  register={register}
                  name="title"
                  error={errors?.title?.message} //Conditional rendering
                />
              </div>
              <div className="col-md-6  mb-3 mt-3">
                <Input
                  label="Position:"
                  placeholder="Number Of Position"
                  type="number"
                  register={register}
                  name="position"
                  error={errors?.position?.message} //Conditional rendering
                />
              </div>

              <div className="col-md-4  mb-3 mt-3">
                <Input
                  label="Job Type:"
                  placeholder="Full Time /Part Time"
                  type="text"
                  register={register}
                  name="Jobtype"
                  error={errors?.Jobtype?.message} //Conditional rendering
                />
              </div>
              <div className="col-md-4  mb-3 mt-3">
                <Input
                  label="Experience:"
                  placeholder="Number Of Years"
                  type="text"
                  register={register}
                  name="experience"
                  error={errors?.experience?.message} //Conditional rendering
                />
              </div>

              <div className="col-md-4 mb-3 mt-3">
                <Input
                  label="Location:"
                  placeholder="City"
                  type="text"
                  register={register}
                  name="location"
                  error={errors?.location?.message} //Conditional rendering
                />
              </div>

              <hr className="mt-3 mb-3" />

              <div className="col-12 mt-3 mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <label htmlFor="">Skils :</label>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      skillFileds.append({ id: Date.now(), value: "" })
                    }
                  >
                    Add Skill
                  </button>
                </div>
                <p className="text-error">{errors?.skils?.message}</p>

                {skillFileds.fields.map(({ id }, index) => (
                  <Controller
                    key={id}
                    name={`skils.${index}.value`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="d-flex align-items-center justify-content-between ">
                        <Input
                          {...field}
                          error={error?.message}
                          containerStyle={{ flex: 1 }}
                        />
                        <button
                          className="delete-data"
                          type="button"
                          onClick={() => skillFileds.remove(index)}
                        >
                          <i
                            className="fa fa-trash text-white"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    )}
                  />
                ))}
              </div>
              <div className="col-12 mt-3 mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <label htmlFor="">Responsibilities :</label>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      responsibility.append({ id: Date.now(), value: "" })
                    }
                  >
                    Add Responsibility
                  </button>
                </div>
                <p className="text-error">
                  {errors?.responsibilities?.message}
                </p>
                {responsibility.fields.map(({ id }, index) => (
                  <Controller
                    key={id}
                    name={`responsibilities.${index}.value`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="d-flex align-items-center justify-content-between ">
                        <Input
                          {...field}
                          error={error?.message}
                          containerStyle={{ flex: 1 }}
                        />
                        <button
                          className="delete-data"
                          type="button"
                          onClick={() => responsibility.remove(index)}
                        >
                          <i
                            className="fa fa-trash text-white"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    )}
                  />
                ))}
              </div>
              <div className="col-12 mt-3 mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <label htmlFor="">Qualifications :</label>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      qualifications.append({ id: Date.now(), value: "" })
                    }
                  >
                    Add Qualifications
                  </button>
                </div>
                <p className="text-error">{errors?.qualification?.message}</p>
                {qualifications.fields.map(({ id }, index) => (
                  <Controller
                    key={id}
                    name={`qualification.${index}.value`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="d-flex align-items-center justify-content-between ">
                        <Input
                          {...field}
                          error={error?.message}
                          containerStyle={{ flex: 1 }}
                        />
                        <button
                          className="delete-data"
                          type="button"
                          onClick={() => qualifications.remove(index)}
                        >
                          <i
                            className="fa fa-trash text-white"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    )}
                  />
                ))}
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <button className="save-add" type="submit">
                  <span>
                    Submit
                    {loading && (
                      <span className="spinner-border spinner-border-sm ms-2"></span>
                    )}
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
