import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, doc, getDocs, deleteDoc, updateDoc, query, orderBy, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
    getStorage, ref, uploadBytes
} from "firebase/storage";
import moment from "moment";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSANGER_SENDING_ID,
    appId: process.env.REACT_APP_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage();

const db = getFirestore(app);
const dbRef = collection(db, "users");
const dbJob = collection(db, "job")


export const applyJob = (data) => {
    data.applydate = moment().format("DD/MM/yyyy HH:mm:ss")
    return addDoc(dbRef, data)
}

export const uploadTaskFile = (file) => {
    const storageRef = ref(storage, `Resume/${file.name}`);
    const metadata = {
        contentType: 'application/pdf',
    };
    return uploadBytes(storageRef, file, metadata)

};


export const getjob = async () => {
    const data = [];
    const { docs } = await getDocs(dbJob)
    docs.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() })
    })
    return data;
}

// get user resume
export const getUserjob = async () => {
    const data = [];
    const que = query(dbRef, orderBy("applydate", "desc"));
    const { docs } = await getDocs(que)
    docs.forEach(doc => {
        data.push({ docId: doc.id, ...doc.data() })
    })
    return data;
}

// get todays resume
export const getToday = async () => {
    const data = [];
    const que = query(dbRef, where("applydate", ">=", moment().format("DD/MM/yyyy")));
    const { docs } = await getDocs(que)
    docs.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() })
    })
    return data;
}

export const deleteUserjob = (id) => {
    const docRef = doc(db, "users", id);
    console.log(id)
    return deleteDoc(docRef)
}

// add all job to job list

export const addJob = (data) => {
    data.applydate = moment().format("DD/MM/yyyy HH:mm:ss")
    return addDoc(dbJob, data)
}

// get all job to job list

export const getAlljob = async () => {
    const data = [];
    const que = query(dbJob, orderBy("applydate", "desc"));
    const { docs } = await getDocs(que)
    docs.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() })
    })
    return data;
}

// delete job

export const deleteJob = async (id) => {
    const docRef = doc(db, "job", id);
    const que = query(dbRef, where("id", "==", id));
    const promises = [];
    try {
        await deleteDoc(docRef)
        const { docs } = await getDocs(que);
        docs.forEach(async d => {
            const ref = doc(db, "users", d.id);
            await deleteDoc(ref)
        })
    } catch (error) {
        console.log(error)
    }
    return Promise.all(promises);

}

// update job

export const update = (id, data) => {
    const docRef = doc(db, "job", id);
    return updateDoc(docRef, data)
}



export default app;