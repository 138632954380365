import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import avtar from "../assets/profile.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { getAlljob } from "../../firebase/firebase";
import { deleteJob } from "../../firebase/firebase";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import alert from "../assets/alert.svg";

export default function JobDetail() {
  const location = useLocation();
  const item = location.state;
  const navigate = useNavigate();
  const handleUpdate = (item) => {
    navigate("/admin/updateJobDetails", { state: item });
  };
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const fetchData = () => {
    getAlljob().then((result) => {
      console.log(result);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (id) => {
    setDeleteId(id);
    setModalShow(true);
  };
  const onDelete = () => {
    setLoading(true);
    deleteJob(deleteId)
      .then((result) => {
        fetchData();
        setModalShow(false);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => setLoading(false));
    navigate("/admin/jobs");
  };
  const handleModalHide = () => {
    setDeleteId(null);
    setModalShow(false);
  };

  return (
    <>
      <SideBar />
      <div className="admin-container">
        <div className="top-detail">
          <div className="d-flex align-items-center">
            <img src={avtar} alt="" />
            <div>
              <h5 className="m-0">Welcome to,Job Details !</h5>
              <p className="m-0">Detail of {item.title}.</p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <button className="edit" onClick={() => handleUpdate(item)}>
              <span>Edit</span>
            </button>
            <button className="delete" onClick={() => handleDelete(item.id)}>
              <span>Delete</span>
            </button>
          </div>
          <MyVerticallyCenteredModal
            show={modalShow}
            onDelete={onDelete}
            onHide={handleModalHide}
            loading={loading}
          />
        </div>
        <div className="detail-job">
          <p
            className="job-title d-flex align-items-center justify-content-start"
            style={{ fontSize: "2.5em" }}
          >
            {item.title}
          </p>
          <div className="details p-4">
            <h6>Qualifications</h6>
            <ul>
              {item.qualification.map((qua) => (
                <li>{qua}</li>
              ))}
            </ul>
            <h6 className="mt-5">Skils</h6>
            <ul>
              {item.skils.map((skil) => (
                <li>{skil}</li>
              ))}
            </ul>

            <h6 className="mt-5">Responsibilities</h6>
            <ul>
              {item.responsibilities.map((res) => (
                <li>{res}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

function MyVerticallyCenteredModal({
  onHide,
  onDelete,
  show,
  loading,
  ...rest
}) {
  return (
    <Modal
      show={show}
      {...rest}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={alert}
            alt=""
            style={{ width: "4em", height: "4em", textAlign: "center" }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center p-2 mb-0 mt-3">
          <h5 className="text-center">Are You Sure Delete This Job ?</h5>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button onClick={onHide}>Close</Button>
        <Button className="btn-danger" onClick={onDelete}>
          Delete
          {loading && (
            <span className="spinner-border spinner-border-sm ms-2"></span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
