import React from "react";
import logo from "../assets/white.svg";
import { useNavigate, NavLink } from "react-router-dom";
import "./style.css";
import { signOut, getAuth } from "firebase/auth";

export default function SideBar() {
  const navigate = useNavigate();
  const auth = getAuth();
  const menuItem = [
    {
      path: "/admin",
      name: "Home",
      iconName: "fa fa-home",
    },
    {
      path: "/admin/application",
      name: "Applications",
      iconName: "fa fa-list-alt",
    },
    {
      path: "/admin/jobs",
      name: "Jobs List",
      iconName: "fa fa-briefcase",
    },
  ];
  return (
    <>
      <div className="sidebar">
        <div className="top_section">
          <h1 className="logo">
            <img src={logo} alt="" className="sidebar-logo" />
          </h1>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className={({ isActive }) => (isActive ? "link active" : "link")}
          >
            <div className="icons">
              {item.iconName ? <i className={item.iconName} /> : item.icon}
            </div>
            <div className="link_text">{item.name}</div>
          </NavLink>
        ))}
        <NavLink
          className="link logout"
          onClick={(e) => {
            e.preventDefault();
            signOut(auth);
            navigate("/login");
          }}
        >
          <div className="icons"></div>
          <div className="link_text">
            <i
              className="fa fa-sign-out me-3"
              aria-hidden="true"
              style={{ marginRight: "1em" }}
            ></i>
            Log Out
          </div>
        </NavLink>
      </div>
    </>
  );
}
