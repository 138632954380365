import React, { useState, useEffect } from "react";
import "../asstes/style.css";
import $ from "jquery";
import hero from "../asstes/applyjob/hero.svg";
import { useNavigate } from "react-router-dom";
import { getjob } from "../firebase/firebase";
import Loading from "../Admin/components/Loading";
import logo from "../asstes/images/vrlogo.svg";

export default function ApplyJob() {
  useEffect(() => {
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 10) {
        $("#header").addClass("is-sticky");
        $(".my-mb-nav").addClass("shadow");
      } else {
        $("#header").removeClass("is-sticky");
        $(".my-mb-nav").removeClass("shadow");
      }
    });
  }, []);

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingvisible, setLoadingVisible] = useState();
  const fetchData = () => {
    setLoadingVisible(true);
    getjob()
      .then(setData)
      .finally(() => setLoadingVisible(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleView = (item) => {
    navigate("/detailJob", { state: item });
  };

  return (
    <>
      <Loading visible={loadingvisible} />
      <header id="header">
        <div className="my-mb-nav p-0">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light d-flex justify-content-between align-item-center">
              <h5
                className="navbar-brand"
                style={{
                  cursor: "pointer",
                  fontSize: "1.8em",
                }}
                onClick={() => navigate("/")}
              >
                <img src={logo} alt="" className="navbar-logo mt-2" />
              </h5>
              <div>
                <div className="others-option d-flex align-item-center flex-1 ">
                  <div className="text-apply">
                    <a href="tel:+919081888829">
                      <button
                        style={{
                          cursor: "pointer",
                        }}
                        className="but button--webcoderskull button--border-thick button--text-medium d-lg-flex d-none"
                      >
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span className="d-lg-inline text-uppercase">
                          Call Us
                        </span>
                      </button>
                    </a>
                    <a href="tel:+919081888829">
                      <button
                        className="but button--webcoderskull button--border-thick button--text-medium d-lg-none d-block"
                        style={{
                          padding: ".6em .8em",
                          borderRadius: "50%",
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa fa-phone m-0"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.5em",
                            color: "blue",
                          }}
                        ></i>
                      </button>
                    </a>
                  </div>
                  <div style={{ marginLeft: "1em" }} className="text-apply">
                    <a href="https://wa.me/919081888829" target="_blank">
                      <button
                        style={{
                          cursor: "pointer",
                        }}
                        className="but button--web button--border-thick button--text-medium d-lg-flex d-none"
                      >
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        <span className="d-lg-inline text-uppercase">
                          Message Us
                        </span>
                      </button>
                    </a>
                    <a href="https://wa.me/919081888829" target="_blank">
                      <button
                        className="but button--webcoderskull button--border-thick button--text-medium d-lg-none d-block"
                        style={{
                          padding: ".6em .8em",
                          borderRadius: "50%",
                          color: "green",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa fa-whatsapp m-0"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.5em",
                            color: "green",
                          }}
                        ></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="p-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="details-apply">
                <div>
                  <h3>Join us as we help you</h3>
                  <h3>build your career,</h3>
                  <h3>it's not just a job.</h3>
                  <p>
                    If you love everything web & mobile, you have landed on the
                    right page. Pick what describes you the best and we will
                    walk the talk.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-apply">
                <img src={hero} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ marginBottom: "7em", paddingBottom: "4em" }}
        >
          <div className="d-flex align-items-center justifycontent-start p-3">
            <p className="job-title">Available Jobs : {data.length}</p>
          </div>
          <div className="row">
            {data.map((item, index) => (
              <div
                className="col-lg-4 col-md-6"
                onClick={() => handleView(item)}
                key={index}
                style={{ cursor: "pointer" }}
              >
                <div className="single-box">
                  <h3 className="m-0 p-0">{item.title}</h3>
                  <small>{item.position} positions</small>
                  <hr />
                  <h6>Qualification</h6>
                  <ul>
                    {item.qualification?.map((qua, index) => (
                      <li key={index}>{qua}</li>
                    ))}
                  </ul>
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <button className="view-more p-0">
                      view more
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
