import React from "react";

export default function TableHeader({ columns, onSort, sortColumn }) {
  const raiseSort = (path) => {
    const sort = { ...sortColumn };
    if (sort.path === path) sort.order = sort.order === "asc" ? "desc" : "asc";
    else {
      sort.path = path;
      sort.order = "asc";
    }
    onSort(sort);
  };
  const renderSortIcon = (column) => {
    if (sortColumn === null) return null;
    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort ms-2" />;
    return <i className="fa fa-sort ms-2" />;
  };
  return (
    <>
      <thead className="bg-dark">
        <tr className="text-center">
          {columns.map((column, i) => (
            <th
              key={i}
              onClick={() => {
                if (column.sortable !== false) {
                  raiseSort(column.path);
                }
              }}
              scope="col"
              style={{
                fontSize: "1em",
                fontWeight: "700",
                color: "#fff",
              }}
            >
              {column.label || column.path}
              {onSort && renderSortIcon(column)}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
}
