import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import avtar from "../assets/profile.svg";
import job from "../assets/Briefcase.png";
import resume from "../assets/Resume.png";
import today from "../assets/Rocket.png";
import Table from "../components/Tables/Table";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { getUserjob } from "../../firebase/firebase";
import { paginate } from "../components/Tables/paginate";
import Loading from "../components/Loading";
import { getAlljob, getToday } from "../../firebase/firebase";

export default function Dashboard() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [totalJob, setTotalJob] = useState();
  const [totalApplication, setTotalApplication] = useState();
  const [todaysApplication, setTodaysApplication] = useState();
  const [loadingvisible, setLoadingVisible] = useState();
  const [datas, setDatas] = useState([]);

  const fetchData = () => {
    setLoadingVisible(true);
    getUserjob()
      .then((result) => {
        setTotalApplication(result.length);
        setDatas(result);
      })
      .catch((e) => {})
      .finally(() => setLoadingVisible(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getAlljob().then((result) => {
      setTotalJob(result.length);
    });
    getToday().then((res) => {
      setTodaysApplication(res.length);
    });
  }, []);

  const handleNewJob = () => {
    navigate("/admin/addNewJob");
  };

  const [sortColumn, setSortColumn] = useState({
    path: "applydate",
    order: "desc",
  });
  const orderColumns = [
    {
      path: "name",
      label: "Name",
    },
    {
      path: "applydate",
      label: "Date",
      content: (item) => {
        return (
          <p>
            {item.applydate.replaceAll("/", "-").split(" ")[0]}
            {/* {moment(new Date(item.applydate)).format("DD/MM/yyyy")} */}
          </p>
        );
      },
    },
    {
      path: "email",
      label: "Email",
    },
    {
      path: "contact",
      label: "Contact",
    },
    {
      path: "title",
      label: "Position",
    },
    {
      path: "file",
      label: "Resume",
      content: (item) => (
        <a
          className="file"
          target="_blank"
          href={item.file}
          style={{ color: "blue !important" }}
        >
          Resume
        </a>
      ),
    },
  ];

  const sorted = _.orderBy(datas, [sortColumn.path], [sortColumn.order]);
  const res = paginate(sorted, currentPage, pageSize);
  return (
    <div>
      <Loading visible={loadingvisible} />
      <SideBar />

      <div className="admin-container">
        <div className="top-detail">
          <div className="d-flex align-items-center">
            <img src={avtar} alt="" />
            <div>
              <h5 className="m-0">Welcome to,Home !</h5>
              <p className="m-0">These are statistics for today.</p>
            </div>
          </div>
          <div>
            <button className="add-job" onClick={handleNewJob}>
              <span>
                Add Job<i className="fa fa-plus ms-2" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
        <div className="dashboard-detail">
          <div className="container">
            <div className="resume-table">
              <h5 className="mb-3 mt-0">Statstics</h5>
            </div>
            <div className="row">
              <div
                className="col-md-4"
                onClick={() => navigate("/admin/jobs")}
                style={{ cursor: "pointer" }}
              >
                <div className="dashboard-cards">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0">Total Jobs</p>
                    <img
                      src={job}
                      alt=""
                      style={{ width: "2em", height: "2em" }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-start">
                    <h1>{totalJob}</h1>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4"
                onClick={() => navigate("/admin/application")}
                style={{ cursor: "pointer" }}
              >
                <div className="dashboard-cards">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0">Total Applications</p>
                    <img
                      src={resume}
                      alt=""
                      style={{ width: "2em", height: "2em" }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-start">
                    <h1>{totalApplication}</h1>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4"
                onClick={() => navigate("/admin/application")}
                style={{ cursor: "pointer" }}
              >
                <div className="dashboard-cards">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0">Today's Applications</p>
                    <img
                      src={today}
                      alt=""
                      style={{ width: "2em", height: "2em" }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-start">
                    <h1>{todaysApplication}</h1>
                  </div>
                </div>
              </div>

              <div className="resume-table w-100">
                <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
                  <h5 className="m-0">Recent Apllications</h5>
                  <Link to="/admin/Application" style={{ color: "blue" }}>
                    View All
                  </Link>
                </div>
                <Table
                  columns={orderColumns}
                  onSort={(sort) => setSortColumn(sort)}
                  data={res}
                  sortColumn={sortColumn}
                  keyFiled="id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
