import React from "react";

export default function Loading({ visible = false }) {
  if (!visible) return null;
  return (
    <div>
      <div className="overlay">
        <div className="overlayDoor"></div>
        <div className="overlayContent">
          <div className="loader">
            <div className="inner"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
