import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import ApplyJob from "./Pages/ApplyJob";
import DetailJob from "./Pages/DetailJob";
import { useEffect } from "react";
import Dashboard from '../src/Admin/Pages/Dashboard'
import Application from '../src/Admin/Pages/Application'
import AddNewJob from '../src/Admin/Pages/AddNewJob'
import JobDetail from '../src/Admin/Pages/JobDetail'
import Jobs from '../src/Admin/Pages/Jobs'
import UpdateJobDetails from '../src/Admin/Pages/UpdateJobDetails'
import Login from '../src/Admin/Pages/Login'
import OTP from '../src/Admin/Pages/OTP'
import PrivateRoutes from '../src/Admin/PrivateRoutes'

function App() {
  const scripts = [
    "./js/jquery.min.js",
    "./js/popper.min.js",
    "./js/bootstrap.min.js",
    "./js/jquery.meanmenu.min.js",
    "./js/wow.min.js",
    "./js/jquery.magnific-popup.min.js",
    "./js/jquery.appear.js",
    "./js/odometer.min.js",
    "./js/slick.js",
    "./js/owl.carousel.min.js",
    "./js/feather.min.js",
    "./js/form-validator.min.js",
    "./js/contact-form-script.js",
    "./js/startp-map.js",
    "./js/main.js",
    "./js/index.js",
    "./js/user.js",
    "./js/jquery-ui.js",
    "./js/validate.js",
  ];

  const loadScript = (src) => {
    var tag = document.createElement("script");
    tag.async = false;
    tag.src = src;
    var body = document.getElementsByTagName("body")[0];
    body.appendChild(tag);
  };

  const init = () => {
    for (var i = 0; i < scripts.length; i++) {
      const src = scripts[i];
      loadScript(src);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Routes>
        <Route exact path='/detailJob' element={<DetailJob />}></Route>
        <Route exact path='/applyJob' element={<ApplyJob />}></Route>
        <Route exact path='/' element={<Home />}></Route>
        <Route exact path='/login' element={<Login />}></Route>
        <Route exact path='/otp' element={<OTP />}></Route>
        <Route exact path='/admin' element={<PrivateRoutes />}>
          <Route exact path='/admin' element={<Dashboard />}></Route>
          <Route exact path='/admin/application' element={<Application />}></Route>
          <Route exact path='/admin/jobs' element={<Jobs />}></Route>
          <Route exact path='/admin/addNewJob' element={<AddNewJob />}></Route>
          <Route exact path='/admin/updateJobDetails' element={<UpdateJobDetails />}></Route>
          <Route exact path='/admin/jobDetail' element={<JobDetail />}></Route>
        </Route>
      </Routes>
    </>
  );

}


export default App;
