import React from "react";
import OtpData from "../components/OtpData";

export default function OTP() {
  return (
    <>
      <OtpData />
    </>
  );
}
