import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import avtar from "../assets/profile.svg";
import Table from "../components/Tables/Table";
import _ from "lodash";
import Pagination from "../components/Tables/Pagination";
import { paginate } from "../components/Tables/paginate";
import { getUserjob } from "../../firebase/firebase";
import { deleteUserjob } from "../../firebase/firebase";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import alert from "../assets/alert.svg";
import Loading from "../components/Loading";

export default function Application() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [inputText, setInputText] = useState("");
  const [loadingvisible, setLoadingVisible] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoadingVisible(true);
    getUserjob()
      .then((result) => {
        setDatas(result);
        setFilterData(result);
      })
      .catch((e) => {})
      .finally(() => setLoadingVisible(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (id) => {
    setDeleteId(id);
    setModalShow(true);
  };
  const onDelete = () => {
    setLoading(true);
    deleteUserjob(deleteId)
      .then((result) => {
        fetchData();
        setModalShow(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      })
      .finally(() => setLoading(false));
  };
  const handleModalHide = () => {
    setDeleteId(null);
    setModalShow(false);
  };

  let inputHandler = (e) => {
    console.log(e.target.value);
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  useEffect(() => {
    const item = datas.filter((i) => i.name.toLowerCase().includes(inputText));
    setFilterData(item);
  }, [inputText]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [sortColumn, setSortColumn] = useState({
    path: "applydate",
    order: "desc",
  });
  const orderColumns = [
    {
      path: "name",
      label: "Name",
    },
    {
      path: "applydate",
      label: "Date",
      content: (item) => {
        return <p>{item.applydate.replaceAll("/", "-").split(" ")[0]}</p>;
      },
    },
    {
      path: "email",
      label: "Email",
    },
    {
      path: "contact",
      label: "Contact",
    },
    {
      path: "title",
      label: "Position",
    },
    {
      path: "file",
      label: "Resume",
      content: (item) => (
        <a className="file" target="_blank" href={item.file}>
          Resume
        </a>
      ),
    },
    {
      path: " ",
      label: "Action",
      content: (item) => (
        <button
          className="delete-data"
          onClick={() => handleDelete(item.docId)}
        >
          <i className="fa fa-trash text-white" aria-hidden="true"></i>
        </button>
      ),
    },
  ];

  const sorted = _.orderBy(filterData, [sortColumn.path], [sortColumn.order]);
  const res = paginate(sorted, currentPage, pageSize);
  return (
    <>
      <SideBar />
      <Loading visible={loadingvisible} />
      <div className="admin-container">
        <div className="top-detail">
          <div className="d-flex align-items-center">
            <img src={avtar} alt="" />
            <div>
              <h5 className="m-0">Welcome to,Applications !</h5>
              <p className="m-0">All application</p>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <b className="ms-3 job-title" style={{ marginLeft: "1em" }}>
            Avilable Applicattion : {datas.length}
          </b>
          <div className="p-2">
            <div className="input-group">
              <div className="form-outline">
                <input
                  type="search"
                  id="form1"
                  className="form-control search"
                  onChange={inputHandler}
                  placeholder="search"
                />
                <div className="form-notch">
                  <div
                    className="form-notch-leading"
                    style={{ width: "9px" }}
                  />
                  <div
                    className="form-notch-middle"
                    style={{ width: "47.2px" }}
                  />
                  <div className="form-notch-trailing" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-seles mt-3 p-4">
          <Table
            columns={orderColumns}
            onSort={(sort) => setSortColumn(sort)}
            data={res}
            sortColumn={sortColumn}
            keyFiled="docId"
            message="There is no data found releated to your search...Try again!"
          />
          <div className="d-flex align-items-center showing-page">
            <div className="count mb-0 justify-content-start flex-1">
              {orderColumns.length > 2 && (
                <p className="mb-0 count-p ms-2">
                  showing {currentPage} of {Math.ceil(datas.length / pageSize)}
                  total result.
                </p>
              )}
            </div>
            <div className="pagination flex-1">
              <Pagination
                itemsCount={datas.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <MyVerticallyCenteredModal
          show={modalShow}
          onDelete={onDelete}
          onHide={handleModalHide}
          loading={loading}
        />
      </div>
    </>
  );
}

function MyVerticallyCenteredModal({
  onHide,
  onDelete,
  show,
  loading,
  ...rest
}) {
  return (
    <Modal
      show={show}
      {...rest}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={alert}
            alt=""
            style={{ width: "4em", height: "4em", textAlign: "center" }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center p-2 mb-0 mt-3">
          <h5 className="text-center">Do You Want To Delete This Resume ?</h5>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button onClick={onHide}>Close</Button>
        <Button className="btn-danger" onClick={onDelete} disabled={loading}>
          Delete
          {loading && (
            <span className="spinner-border spinner-border-sm ms-2"></span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
