import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../asstes/style.css";

export default function MyModal({
  show,
  size,
  title,
  data,
  centered,
  handleClose,
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered={centered}
        size={size}
      >
        <Modal.Body>
          <svg
            id="completion"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 96 101"
          >
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n    .st0{fill:#51BBA0;fill-opacity:0.4;} .st1{fill:#51BBA0;fill-opacity:0.1;} .st2{fill:#51BBA0;}\n  ",
              }}
            />
            <title>C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9</title>
            <g id="configurator">
              <g id="configurator_completion">
                <g id="stars">
                  <circle id="Oval" className="st0" cx={14} cy={18} r={1} />
                  <circle
                    id="Oval-Copy-4"
                    className="st0"
                    cx={27}
                    cy={20}
                    r={1}
                  />
                  <circle
                    id="Oval-Copy-10"
                    className="st0"
                    cx={76}
                    cy={20}
                    r={1}
                  />
                  <circle
                    id="Oval-Copy-2"
                    className="st0"
                    cx="61.5"
                    cy="12.5"
                    r="1.5"
                  />
                  <circle
                    id="Oval-Copy-9"
                    className="st0"
                    cx={94}
                    cy={53}
                    r={1}
                  />
                  <circle
                    id="Oval-Copy-6"
                    className="st0"
                    cx={88}
                    cy={14}
                    r={1}
                  />
                  <circle
                    id="Oval-Copy-7"
                    className="st0"
                    cx={59}
                    cy={1}
                    r={1}
                  />
                  <circle id="Oval_1_" className="st0" cx={43} cy={9} r={2} />
                  <path
                    id="ster-01"
                    className="st0"
                    d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"
                  />
                  <path
                    id="ster-01"
                    className="st0"
                    d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"
                  />
                  <path
                    id="ster-01"
                    className="st0"
                    d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"
                  />
                  <circle
                    id="Oval-Copy-5"
                    className="st0"
                    cx={91}
                    cy={40}
                    r={2}
                  />
                  <circle
                    id="Oval-Copy-3"
                    className="st0"
                    cx={7}
                    cy={36}
                    r={2}
                  />
                  <circle
                    id="Oval-Copy-8"
                    className="st0"
                    cx="7.5"
                    cy="5.5"
                    r=".5"
                  />
                </g>
              </g>
            </g>
            <g id="cirkel">
              <g id="Mask">
                <path
                  id="path-1_1_"
                  className="st1"
                  d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"
                />
              </g>
            </g>
            <path
              id="check"
              className="st2"
              d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"
            />
          </svg>
          <h4 className="text-center"> {data}</h4>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
