import React, { useState, useEffect } from "react";
import "./style.css";
import logo from "../assets/VR logo.svg";
import loader from "../assets/Loader.gif";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useForm } from "react-hook-form";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import "../../firebase/firebase";

const SECONDS = 60;

export default function OtpData() {
  const authentication = getAuth();
  const [counter, setCounter] = useState(SECONDS);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const {
    handleSubmit, 
    errors,
    formState: { isSubmitting },
  } = useForm({});
  const onSubmit = (data) => {
    if (OTP.length === 6 && OTP.match(/^[0-9]{1,6}$/)) {
      setLoading(true);
      window.confirmationResult
        .confirm(OTP)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          // ...
          localStorage.setItem("phone", location.state.phone);
          navigate("/admin", { replace: true });
        })
        .catch((error) => {
          console.log(error);
          alert("Enter Valid OTP");
          setLoading(false);
        });
    } else {
      alert("please enter OTP");
    }
  };

  const generateOTP = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      authentication
    );
  };

  const startSignIn = () => {
    signInWithPhoneNumber(
      authentication,
      `+${location.state.phone}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.log(error);
      });
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleResend = () => {
    setCounter(SECONDS);
    startSignIn();
  };
  useEffect(() => {
    generateOTP();
    startSignIn();
  }, []);

  const ExpiredNotice = () => {
    return (
      <div className="expired-notice text-center">
        <span>
          <span className="text-center">Didn't get the code? </span>
          <span
            className="resend-otp-link anchor-1 text-center"
            onClick={handleResend}
          >
            Resend OTP
          </span>
        </span>
      </div>
    );
  };
  return (
    <>
      <div id="recaptcha-container"></div>
      <div className="container login">
        <div className="row">
          <div className="col-md-6">
            <img src={logo} className="logo-admin" alt="" />
            <div className="details-admin">
              <h2 className="m-0">Welcome To,</h2>
              <h2 className="heading">VR GROUPS</h2>
              <p className="detail-p">
                please enter your OTP here,
                <br /> {location.state.phone}
              </p>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                <div className="mb-3">
                  <label className="form-label mb-3">Enter Valid OTP</label>
                  <OtpInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus={true}
                    numInputs={6}
                    otpType="number"
                    className="otp-data"
                  />
                </div>

                <label className="field-label1 d-flex justify-content-start mt-2">
                  {counter > 0 ? (
                    <div className="text-center">0: {counter}</div>
                  ) : (
                    <ExpiredNotice />
                  )}
                </label>
                <div className="d-flex align-items-center justify-content-start mt-5">
                  <button
                    className="login-data"
                    type="submit"
                    disabled={loading}
                  >
                    VERIFY
                    {loading && (
                      <span className="spinner-border spinner-border-sm ms-2"></span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 p-0 m-0">
            <div className="right-img d-none d-lg-block">
              <img src={loader} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
