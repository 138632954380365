import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { useState } from "react";

export default function PrivateRoutes() {
  const auths = getAuth();
  const [user, setUser] = useState();
  useEffect(() => {
    onAuthStateChanged(auths, (user) => {
      setUser(user);
    });

    return () => {};
  }, []);

  return user !== null ? <Outlet /> : <Navigate to="/login" replace />;
}
