import React from "react";
import "./style.css";
import logo from "../assets/VR logo.svg";
import loader from "../assets/Loader.gif";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

const schema = yup.object({
  phone: yup
    .string()
    .label("Phone Number")
    .oneOf(["9081888829"], "Unauthorized Access")
    .required()
    .matches(/^[6-9]{1}[0-9]{9}$/, "Enter Valid Number"),
});

export default function LoginInfo() {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = (data) => {
    data.phone = `+91${data.phone}`;
    navigate("/otp", { replace: true, state: data });
    // alert("submited");
  };
  return (
    <>
      <div className="container login">
        <div className="row">
          <div className="col-md-6">
            <img src={logo} className="logo-admin" alt="" />
            <div className="details-admin">
              <h2 className="m-0">Welcome To,</h2>
              <h2 className="heading">VR GROUPS</h2>
              <p className="detail-p">
                please enter your phone number to login to the system
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <label className="form-label m-0">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    maxLength={10}
                    {...register("phone")}
                  />
                  {errors.phone && (
                    <span className="text-error">{errors.phone?.message}</span>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-start mt-5">
                  <button className="login-data" type="submit">
                    LOG IN
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 p-0 m-0">
            <div className="right-img d-none d-lg-block">
              <img src={loader} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
