import React from "react";

export default function TableBody({ keyFiled, data, columns, message }) {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return item[column.path];
  };
  return (
    <>
      <tbody>
        {data.length > 0 ? (
          data.map((item, index) => (
            <tr key={index}>
              {columns.map((column, i) => (
                <td
                  className="text-center"
                  key={column[keyFiled] || i}
                  style={{
                    fontSize: ".9em",
                    cursor: "pointer",
                  }}
                >
                  {renderCell(item, column)}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columns.length}>
              <p className="text-center fw-bold">{message}</p>
            </td>
          </tr>
        )}
      </tbody>
    </>
  );
}
