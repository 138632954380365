import React from "react";
import LoginInfo from "../components/LoginInfo";

export default function Login() {
  return (
    <>
      <LoginInfo />
    </>
  );
}
