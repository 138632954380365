import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import avtar from "../assets/profile.svg";
import { useNavigate } from "react-router-dom";
import "../components/style.css";
import { getAlljob } from "../../firebase/firebase";
import { deleteJob } from "../../firebase/firebase";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import alert from "../assets/alert.svg";
import Loading from "../components/Loading";

export default function Jobs() {
  const navigate = useNavigate();
  const [loadingvisible, setLoadingVisible] = useState();
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNewJob = () => {
    navigate("/admin/addNewJob");
  };
  const fetchData = () => {
    setLoadingVisible(true);
    getAlljob()
      .then(setData)
      .finally(() => setLoadingVisible(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (id) => {
    setDeleteId(id);
    setModalShow(true);
  };
  const onDelete = () => {
    setLoading(true);
    deleteJob(deleteId)
      .then((result) => {
        fetchData();
        setModalShow(false);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => setLoading(false));
    navigate("/admin/jobs");
  };
  const handleModalHide = () => {
    setDeleteId(null);
    setModalShow(false);
  };
  const handleView = (item) => {
    navigate("/admin/jobDetail", { state: item });
  };
  const handleUpdate = (item) => {
    navigate("/admin/updateJobDetails", { state: item });
  };
  return (
    <>
      <Loading visible={loadingvisible} />
      <SideBar />
      <div className="admin-container">
        <div className="top-detail">
          <div className="d-flex align-items-center">
            <img src={avtar} alt="" />
            <div>
              <h5 className="m-0">Welcome to,Jobs !</h5>
              <p className="m-0">All Jobs</p>
            </div>
          </div>
          <div>
            <button className="add-job" onClick={handleNewJob}>
              <span>
                Add Job<i className="fa fa-plus ms-2" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
        <div className="job-contaner">
          <div className="container">
            <div className="d-flex align-items-center justifycontent-start p-3">
              <p className="job-title">Avilable Jobs : {data.length}</p>
            </div>
            <div className="row">
              {data.map((item, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="single-box">
                    <h3 className="m-0 p-0">{item.title}</h3>
                    <small>{item.position} positions</small>
                    <hr />
                    <h6>Qualification</h6>
                    <ul style={{ listStyle: "disc" }}>
                      {item.qualification.map((qua) => (
                        <li>{qua}</li>
                      ))}
                    </ul>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <div className="d-flex align-items-center">
                        <button
                          className="edit"
                          onClick={() => handleUpdate(item)}
                        >
                          <span>Edit</span>
                        </button>
                        <button
                          className="delete"
                          onClick={() => handleDelete(item.id)}
                        >
                          <span>Delete</span>
                        </button>
                      </div>
                      <button
                        className="view-more p-0"
                        onClick={() => handleView(item)}
                      >
                        view more
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <MyVerticallyCenteredModal
              show={modalShow}
              onDelete={onDelete}
              onHide={handleModalHide}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function MyVerticallyCenteredModal({
  onHide,
  onDelete,
  show,
  loading,
  ...rest
}) {
  return (
    <Modal
      show={show}
      {...rest}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={alert}
            alt=""
            style={{ width: "4em", height: "4em", textAlign: "center" }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center p-2 mb-0 mt-3">
          <h5 className="text-center">Are You Sure Delete This Job ?</h5>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button onClick={onHide}>Close</Button>
        <Button className="btn-danger" onClick={onDelete} disabled={loading}>
          Delete
          {loading && (
            <span className="spinner-border spinner-border-sm ms-2"></span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
