import CountUp from "react-countup";
import loader from "../asstes/Loader.gif";
import Typed from "react-typed";
import { NavLink, Link } from "react-router-dom";
import ScrollTrigger from "react-scroll-trigger";
import { useState, useEffect } from "react";
import logo from "../asstes/images/vrlogo.svg";

export default function Home() {
  const [counterOn, setCounterOn] = useState(false);
  const [finish, setFinish] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showVisible, setShowVisible] = useState(false);
  const toRotate = [
    "Backend Devloper",
    "Fontend Devloper",
    "Ios Devloper",
    "Android Devloper",
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    setText(fullText);
    setLoopNum(loopNum + 1);
    setIndex(1);
    setDelta(2000);
  };

  useEffect(() => {
    $(function () {
      //Scroll event
      $(window).on("scroll", function () {
        var scrolled = $(window).scrollTop();
        if (scrolled > 300) $(".go-top").fadeIn("slow");
        if (scrolled < 300) $(".go-top").fadeOut("slow");
      });
      //Click event
      $(".go-top").on("click", function () {
        $("html, body").animate({ scrollTop: "0" }, 500);
      });
    });
  }, []);

  localStorage.removeItem("phone");
  return (
    <>
      {/* LOGINING */}
      {loading && (
        <div className="page-load">
          <div className="banner">
            <img className="gif" src={loader} alt="" srcSet />
            <div id="typed-strings">
              <h1>VR GROUPS</h1>
              <p>Practical &amp; Professional</p>
            </div>
            <h2>
              <Typed
                strings={["VR GROUPS", "Practical &amp; Professional"]}
                typeSpeed={40}
              />
            </h2>
          </div>
        </div>
      )}
      {/* End Preloader Area */}
      {/* Start Navbar Area */}
      <header id="header">
        <div className="my-mb-nav d-flex d-lg-none justify-content-between align-items-center">
          <div className="my-logo">
            <h4 style={{ color: "black", cursor: "pointer" }}>
              <img src={logo} alt="" className="navbar-logo mt-2" />
            </h4>
          </div>
          <div className="ham-manu" onClick={() => setShow(true)}>
            <img src="images/navbar.png" alt="" className="open-nav" />
          </div>
        </div>
        {/* Slide bar */}
        {show && (
          <div className="slide-bar">
            <div className="holder">
              <div className="upper-slide">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 style={{ cursor: "pointer" }}>
                    VR GROUPS
                    <a
                      href
                      className="slog text-white"
                      style={{ color: "#FFF" }}
                    >
                      Practical &amp; Professional
                    </a>
                  </h4>
                  <img
                    src="images/gtmatrix/w_cross.png"
                    alt=""
                    srcSet
                    className="close-btn"
                    onClick={() => setShow(false)}
                  />
                </div>
              </div>
              <div className="body-slide">
                <ul className="navbar-nav d-block">
                  <li className="nav-item">
                    <a
                      href="#"
                      className="nav-link active"
                      onClick={() => setShow(false)}
                    >
                      Home{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#service"
                      className="nav-link"
                      onClick={() => setShow(false)}
                    >
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#about"
                      className="nav-link"
                      onClick={() => setShow(false)}
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#contacts"
                      className="nav-link"
                      onClick={() => setShow(false)}
                    >
                      Contact
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="tel:+919081888829"
                      className="nav-link login-btn btn btn-primary"
                      onClick={() => setShow(false)}
                    >
                      Call Us
                    </a>
                  </li>
                </ul>
                <div className="slide-contact">
                  <h4>Company Info</h4>
                </div>
                <ul className="company-info">
                  <li>
                    <i data-feather="map-pin" /> 924, The Spire 2, Shital Park
                    Circle,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;150ft Ring
                    Road,Rajkot-360005,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gujarat,
                    India.
                  </li>
                  <li>
                    <i data-feather="mail" /> Email:
                    <a
                      href="mailto:welcome@thevrgroups.com"
                      className="a-color"
                    >
                      welcome@thevrgroups.com
                    </a>
                  </li>
                  <li>
                    <i data-feather="phone-call" /> Phone:
                    <a href="tel:+919081888829" className="a-color">
                      090818 88829
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className="startp-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <h5
                className="navbar-brand"
                style={{ cursor: "pointer" }}
                onClick="window.location.href = '#'"
              >
                <img src={logo} alt="" className="navbar-logo mt-2" />
              </h5>
              <div
                className="collapse navbar-collapse mean-menu d-md-none"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav nav ml-auto">
                  <li className="nav-item">
                    <a href="#" className="nav-link home" onClick="color(this)">
                      Home{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#service"
                      className="nav-link service"
                      onClick="color(this)"
                    >
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#about"
                      className="nav-link about"
                      onClick="color(this)"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#contacts"
                      className="nav-link contact"
                      onClick="color(this)"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className="others-option">
                <a
                  href="tel:+919081888829"
                  className="btn btn-primary login-btn"
                  onClick="openLogin()"
                >
                  Call Us
                </a>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {/* End Navbar Area */}

      {/* Start Main Banner */}
      <div className="main-banner" id="particles-js">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row h-100 justify-content-center align-items-center">
                <div className="col-lg-5">
                  <div className="hero-content">
                    <h1 className="m-0 text-uppercase">Your Step To A</h1>
                    <h1 className="m-0 text-uppercase">New Career</h1>
                    <h5 className=" mb-3 carrer-vr blue text-capitalize">
                      {`Build your career as `}

                      <span
                        className="txt-rotate"
                        dataPeriod="1000"
                        data-rotate='[ "Backend Devloper", "Fontend Devloper","Ios Devloper" ,"Android Devloper"]'
                      >
                        <span className="wrap text-c">{text}</span>
                      </span>
                    </h5>
                    <div className="others-option mt-5">
                      <NavLink
                        to="/applyJob"
                        className="btn btn-primary login-btn"
                      >
                        Apply for job
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1">
                  <div className="banner-image">
                    <img
                      src="images/update/man.webp"
                      className="wow fadeInDown"
                      data-wow-delay="3.4s"
                      alt="man"
                    />
                    <img
                      src="images/update/chet.webp"
                      className="wow fadeInUp"
                      data-wow-delay="3.6s"
                      alt="code"
                    />
                    <img
                      src="images/update/data.webp"
                      className="wow fadeInDown"
                      data-wow-delay="4s"
                      alt="carpet"
                    />
                    <img
                      src="images/update/vr box.webp"
                      className="wow fadeInDown"
                      data-wow-delay="4.4s"
                      alt="bin"
                    />
                    <img
                      src="images/update/stack.webp"
                      className="wow fadeInDown"
                      data-wow-delay="4.8s"
                      alt="book"
                    />
                    <img
                      src="images/update/stack.webp"
                      className="wow d-none fadeInDown"
                      data-wow-delay="5.2s"
                      alt="dekstop"
                    />
                    <img
                      src="images/update/wall-e.webp"
                      className="wow fadeInDown"
                      data-wow-delay="5.2s"
                      alt="dot"
                    />
                    <img
                      src="images/update/i-robot.webp"
                      className="wow fadeInDown"
                      data-wow-delay="5.6s"
                    />
                    <img
                      src="images/update/HERO.webp"
                      className="wow text-center w-100 fadeInUp"
                      data-wow-delay="3.4s"
                      alt="main-pic"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape1">
          <img src="images/shape1.png" alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src="images/shape2.svg" alt="shape" />
        </div>
        <div className="shape3">
          <img src="images/shape3.svg" alt="shape" />
        </div>
        <div className="shape4">
          <img src="images/shape4.svg" alt="shape" />
        </div>
        <div className="shape5">
          <img src="images/shape5.png" alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src="images/shape4.svg" alt="shape" />
        </div>
        <div className="shape7">
          <img src="images/shape4.svg" alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src="images/shape2.svg" alt="shape" />
        </div>
      </div>
      {/* Start Boxes Area */}
      <section className="boxes-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-box">
                <div className="icon">
                  <i className="fa fa-user fs-4 dark-green " />
                </div>
                <h3>Professional attitude</h3>
                <p style={{ fontSize: "14px" }}>
                  Profound knowledge, global experience and superior work models
                  we follow are the reason for establishing trustful long-term
                  business relationship with our customers
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-box bg-f78acb">
                <div className="icon">
                  <i
                    className="fa fa-volume-control-phone fs-4 bg-f78acb"
                    aria-hidden="true"
                  ></i>
                </div>
                <h3>
                  Communication
                  <br /> and support
                </h3>
                <p style={{ fontSize: "14px" }}>
                  We go for flexibility and transparency. This approach allows
                  implementing effective communication strategies with each
                  particular client ensuring productivity and excellent results
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-box bg-c679e3">
                <div className="icon">
                  <i className="fa fa-line-chart fs-4" aria-hidden="true"></i>
                </div>
                <h3>Expert level</h3>
                <p style={{ fontSize: "14px" }}>
                  Our specialists can efficiently create any project from
                  scratch as well as join in on any development stage. We also
                  offer Dedicated Team or Dedicated Expert option sharing our
                  best practice and expertise
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-box bg-eb6b3d">
                <div className="icon">
                  <i className="fa fa-shield fs-4" aria-hidden="true"></i>
                </div>
                <h3>Professionals</h3>
                <p style={{ fontSize: "14px" }}>
                  Most of our team members are Senior level engineers.
                  International certificates of competence, excellent skills and
                  years of priceless experience allow them to cover the most
                  challenging tasks
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Start Features Area */}
      <section className="features-area ptb-80 bg-f7fafd" id="service">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <div className="bar" />
            <p style={{ fontSize: "14px" }}>
              VR GROUPS has the experience and expertise to help your business
              meet your goals and objectives faster, with less risk. VR GROUPS
              is an IT service and consulting Company who assists customers in
              achieving their business objectives with the use of technology.
            </p>
          </div>
          <section
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            className="vc_section new-services-section ptb-80"
          >
            <div className="container">
              <div className="vc_row wpb_row vc_row-fluid">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="row align-items-center marg">
                        <div className="col-lg-6 iot-features-content">
                          <h3>Big Data</h3>
                          <p>
                            Big data is not a technology initiative that should
                            be reserved for data scientists and IT departments.
                            Big data is a strategic business opportunity that
                            requires technical understanding and organizational
                            coordination. To be successful, companies need to
                            deeply anchor big data and analytics in their
                            organization so that information and knowledge can
                            be exchanged between business units and functions.
                          </p>
                          <p>
                            With our experience as a strategy consultant and our
                            expertise in the area of advanced analytics, we
                            support your company in developing the skills
                            necessary to not only collect data, but to use it in
                            a targeted manner.
                          </p>
                          {/* <a href="#" className="btn btn-primary">Read More</a> */}
                        </div>
                        <div className="col-lg-6 iot-features-image">
                          <img
                            src="images/gtmatrix/cercle-shape.png"
                            className="bg-image rotateme"
                            alt="shape"
                          />
                          <img
                            src="images/update/BIG DATA.webp"
                            alt="Big Data"
                            className="wow w-75 fadeInUp upme"
                            data-wow-delay="0.6s"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center marg">
                        <div className="col-lg-6 iot-features-image">
                          <img
                            src="images/cercle-shape.png"
                            className="bg-image rotateme"
                            alt="shape"
                          />
                          <img
                            src="images/update/AI2.webp"
                            alt="Artificial intelligence"
                            className="wow w-75 fadeInUp upme"
                            data-wow-delay="0.6s"
                          />
                        </div>
                        <div className="col-lg-6 iot-features-content">
                          <h3>Artificial intelligence </h3>
                          <p>
                            Artificial intelligence is based on the principle
                            that human intelligence can be defined in a way that
                            a machine can easily mimic it and execute tasks,
                            from the most simple to those that are even more
                            complex.
                          </p>
                          <p>
                            {" "}
                            Many tools are used in AI, including versions of
                            search and mathematical optimization, artificial
                            neural networks, and methods based on statistics,
                            probability and economics.{" "}
                          </p>
                          {/* <a href="#" className="btn btn-primary">Read More</a> */}
                        </div>
                      </div>
                      <div className="row align-items-center marg">
                        <div className="col-lg-6 iot-features-content">
                          <h3>Internet Of Things</h3>
                          <p>
                            Do you want to increase the efficiency of your
                            processes and bring smart, networked products to the
                            market? Your goal is nothing less than a new
                            business model? IoT has made complete automation
                            possible. The solutions like Smart cities, Smart
                            Home, Smart Factory, and Connected Vehicle are
                            possible only because of IoT.
                          </p>
                          <p>
                            This technology will indeed help in streamlining
                            business processes and thereby boost productivity to
                            a great extent. It will give you the ability to
                            provide better products and services. We are a team
                            of experts who are passionate about service and
                            customer happiness. We deliver extraordinary results
                            for future IoT Services and Products.
                          </p>
                          {/* <a href="#" className="btn btn-primary">Read More</a> */}
                        </div>
                        <div className="col-lg-6 iot-features-image">
                          <img
                            src="images/cercle-shape.png"
                            className="bg-image rotateme"
                            alt="shape"
                          />
                          <img
                            src="images/update/IOT.webp"
                            alt="Internet Of Things"
                            className="wow fadeInUp w-75 upme"
                            data-wow-delay="0.6s"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center marg">
                        <div className="col-lg-6 iot-features-image">
                          <img
                            src="images/cercle-shape.png"
                            className="bg-image rotateme"
                            alt="shape"
                          />
                          <img
                            src="images/cloud computing.webp"
                            alt="Cloud Computing"
                            className="wow w-75 fadeInUp upme"
                            data-wow-delay="0.6s"
                          />
                        </div>
                        <div className="col-lg-6 iot-features-content">
                          <h3>Cloud computing</h3>
                          <p>
                            If you are looking for a prominent Cloud computing
                            service provider, VR GROUPS is the place for you. We
                            have a pool of highly qualified, skilled &amp;
                            hardworking software engineers, who work around the
                            clock to impart custom build solutions and policies
                            that help transform large, medium, &amp; small
                            enterprises, who are planning their move to the
                            Cloud. From consultation, implementation, to
                            support, with our expertise, we make the most
                            daunting tasks simple &amp; hassle free.
                          </p>
                          <p>
                            Being experienced in IT infrastructure management
                            &amp; transformational services, we offer various
                            cloud computing services for Microsoft Azure ,
                            Amazon Web Services (AWS) , Google Cloud ,
                            Salesforce
                          </p>
                          {/* <a href="#" className="btn btn-primary">Read More</a> */}
                        </div>
                      </div>
                      <div className="row align-items-center marg">
                        <div className="col-lg-6 iot-features-content">
                          <h3>Virtual Reality</h3>
                          <p>
                            At VR GROUPS, we use a well-balanced set of
                            technologies to create incredible solutions that
                            will make you stand out from the crowd. Our team
                            focuses on custom solutions for the top virtual
                            reality platforms, including HTC Vive and Windows
                            Mixed Reality.
                          </p>
                          <p>
                            Augmented Reality (AR) and Virtual Reality (VR) are
                            unique techniques that have introduced a new class
                            of simulation, with potential implementations in the
                            health and education sectors, as well as possibly
                            disrupting the traditional methods of
                            communication.Our extensive experience is put to
                            good use, ensuring that our clients have the
                            highest-value technical solution for their
                            particular business challenge.
                          </p>
                          {/* <a href="#" className="btn btn-primary">Read More</a> */}
                        </div>
                        <div className="col-lg-6 iot-features-image">
                          <img
                            src="images/cercle-shape.png"
                            className="bg-image rotateme"
                            alt="shape"
                          />
                          <img
                            src="images/update/VR.webp"
                            alt="Virtual Reality"
                            className="wow w-75 fadeInUp upme"
                            data-wow-delay="0.6s"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center marg">
                        <div className="col-lg-6 iot-features-image">
                          <img
                            src="images/cercle-shape.png"
                            className="bg-image rotateme"
                            alt="shape"
                          />
                          <img
                            src="images/update/Digital Marketing.webp"
                            alt="Digital Marketing"
                            className="wow w-75 fadeInUp upme"
                            data-wow-delay="0.6s"
                          />
                        </div>
                        <div className="col-lg-6 iot-features-content">
                          <h3>Digital Marketing</h3>
                          <p>
                            VR GROUPS is a marketing and technology agency that
                            creates digital media formats and campaigns which
                            reach and involve people. We use our expertise to
                            help our clients exploit the opportunities that
                            digital transformation provides in all areas of
                            communication, marketing and internal organisation.
                          </p>
                          <p>
                            We offer you the whole package of digital marketing
                            tools which includes but is not limited to SEO, SMM,
                            Content Marketing, Inbound marketing, and PPC to
                            take your company to a new level. Our company
                            realizes the power of these tools and designs unique
                            strategies to employ them properly and get the best
                            of them. We approach all the tasks with great
                            responsibility and reach the targets within the set
                            deadlines. Our experienced team members take the
                            customers’ wishes and turn them into an integrated
                            and coordinated plan which guarantees the desired
                            results.
                          </p>
                          {/* <a href="#" className="btn btn-primary">Read More</a> */}
                        </div>
                      </div>
                      <div className="row align-items-center marg">
                        <div className="col-lg-6 iot-features-content">
                          <h3>Software Soluition</h3>
                          <p>
                            VR GROUPS is an India-based software company. We
                            have been developing software products and custom
                            software solutions for medium-sized and large
                            companies since 2012.
                          </p>
                          <p>
                            Need a solid software foundation for your business?
                            Uncoordinated mobile apps and software stress out
                            your employees and impede your productivity?
                          </p>
                          <p>
                            Your business needs a reliable enterprise software
                            solution to support your many operations. It needs
                            to scale, coordinate other software and mobile apps,
                            incorporate advanced tech, and cover an extensive
                            list of other objectives. VR GROUPS has the
                            experience, tools, and technologies to create such
                            demanding custom software.
                          </p>
                          {/* <a href="#" className="btn btn-primary">Read More</a> */}
                        </div>
                        <div className="col-lg-6 iot-features-image">
                          <img
                            src="images/cercle-shape.png"
                            className="bg-image rotateme"
                            alt="shape"
                          />
                          <img
                            src="images/update/SoftwareSolution.webp"
                            alt="Software Solution"
                            className="wow w-75 fadeInUp upme"
                            data-wow-delay="0.6s"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="vc_row-full-width vc_clearfix" />
        </div>
      </section>
      {/* why choose us */}
      <section className="why-choose-us">
        <div className="container">
          <div className="row d-lg-flex justify-content-lg-between align-items-center marg">
            <div className="col-lg-6 why-choose-us-intro order-lg-2">
              <h3 className="why-choose-us-text">
                Why choose our services for your business ?
              </h3>
              <p>
                As a one-stop service provider, we strive hard to offer scalable
                and powerful solutions for your business. Our years of
                experience in the industry help us in delivering true value
                solutions for all kinds of needs. Our highly robust, secure and
                affordable solutions aim at fulfilling your needs. You will find
                pricing to be very reasonable compared to other companies.
              </p>
              {/* 
                    <button className="btn btn-primary">Take a tour</button> */}
            </div>
            <div className="col-lg-6 text-center order-lg-1">
              <img
                src="images/cercle-shape.png"
                className="bg-image text-center rotateme"
                alt="shape"
              />
              <img
                src="images/our services.webp"
                alt="Why Choose Us"
                className="wow w-75 fadeInUp"
                data-wow-delay="0.4s"
              />
            </div>
          </div>
          <div className="row d-lg-flex align-items-center marg">
            <div className="col-lg-6 why-choose-us-tour  order-lg-1">
              <h3 className="why-choose-us-text">
                We make you ultra productive
              </h3>
              <p>
                Our services and designed products are helpful in deploying
                resources more quickly by providing the highest levels of safety
                and reliability for the business operations in today's
                competitive environment. As a result, businesses can reduce
                their costs and improve overall return on investment
              </p>
              <ul className="why-choose-us-list">
                <li className="list-one">
                  <span>
                    <h6>Solve Complex Problems</h6>
                    <p>
                      Improved hardware combined with smarter applications have
                      made it easier to research data, analyze it, and plan
                      scalability.
                    </p>
                  </span>
                </li>
                <li className="list-one">
                  <span>
                    <h6>Improved Marketing</h6>
                    <p>
                      Internet marketing using online advertising methods (SEO,
                      PPC, Facebook Ads) are far more accurate ways than
                      traditional marketing of finding target audiences,
                      discovering their needs, and building a marketing campaign
                      to persuade them to buy.
                    </p>
                  </span>
                </li>
                <li className="list-one">
                  <span>
                    <h6>Improved Customer Support </h6>
                    <p>
                      Customers can receive support from multiple channels
                      telephone, emails, social media platforms, webinars, and
                      so on. Additionally, customer relationship management
                      systems help businesses understand customer behavior.
                    </p>
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 text-center  order-lg-2">
              <img
                src="images/cercle-shape.png"
                className="bg-image text-center rotateme"
                alt="shape"
              />
              <img
                src="images/ultra product.webp"
                alt="Ultra Productive"
                className="wow w-100 fadeInUp"
                data-wow-delay="0.6s"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Start Fun Facts Area */}
      <section className="funfacts-area ptb-80" id="about">
        <div className="container">
          <div className="section-title">
            <h2>Why VR GROUPS will be a great fit for you</h2>
            <div className="bar" />
            <p>
              8 years of experience and best practices that help us deliver
              perfect products and exceed your expectations.
            </p>
          </div>
          <ScrollTrigger
            onEnter={() => {
              if (finish === false) setCounterOn(true);
            }}
            onExit={() => {
              setCounterOn(false);
              setFinish(true);
            }}
          >
            <div className="row">
              <div className="col-lg-3 col-md-3 col-6">
                <div className="funfact">
                  <h3>
                    {counterOn ? (
                      <CountUp end={"24"} duration={2} delay={0} />
                    ) : (
                      <span>24</span>
                    )}
                    /
                    {counterOn ? (
                      <CountUp end={"7"} duration={2} delay={0} />
                    ) : (
                      <span>7</span>
                    )}
                  </h3>
                  <p>Support</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-6">
                <div className="funfact">
                  <h3>
                    {counterOn ? (
                      <CountUp end={"20"} duration={2} delay={0} />
                    ) : (
                      <span>20</span>
                    )}
                    +
                  </h3>
                  <p>Developers</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-6">
                <div className="funfact">
                  <h3>
                    {counterOn ? (
                      <CountUp end={"60"} duration={2} delay={0} />
                    ) : (
                      <span>60</span>
                    )}
                    +
                  </h3>
                  <p>Projects done</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-6">
                <div className="funfact">
                  <h3>
                    {counterOn ? (
                      <CountUp end={"10"} duration={2} delay={0} />
                    ) : (
                      <span>10</span>
                    )}
                    +
                  </h3>
                  <p>Years of Experience</p>
                </div>
              </div>
            </div>
          </ScrollTrigger>
          <div className="map-bg">
            <img src="images/map.png" alt="map" />
          </div>
        </div>
      </section>
      {/* Start Ready To Talk Area */}
      <section className="ready-to-talk">
        <div className="container">
          <h3>Need some help?</h3>
          <p>
            If you can not find the answer to your question , you can always
            contact us.
          </p>
          <a href="tel:+919081888829" className="btn btn-primary">
            Contact Us
          </a>
          <span>
            <a
              href="javascript:void(0)"
              className="showaddress"
              onClick={() => setShowVisible(true)}
            >
              OR , Visit Our Office
            </a>
          </span>
          {showVisible && (
            <div className="address-bar">
              <img
                src="images/cross.png"
                className="add-close"
                onClick={() => setShowVisible(false)}
                style={{ cursor: "pointer" }}
              />
              <div className="address-img d-flex justify-content-center align-items-center">
                <img src="svg/map.svg" alt="" srcSet />
              </div>
              <div className="address-disc">
                <p className="address-disc-para">
                  924, The Spire 2, Shital Park Circle,
                  <br /> 150ft Ring Rd, Rajkot-360005, Gujarat, India.
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <a
                  href="https://goo.gl/maps/ykht5pX9pyu1Jkba8"
                  target="_blank"
                  onClick="window.open('https://goo.gl/maps/ykht5pX9pyu1Jkba8')"
                  className="btn btn-primary"
                >
                  Look at map
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
      <footer className="new_footer_area bg_color" id="contacts">
        <div className="new_footer_top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  className="f_widget company_widget wow fadeInLeft"
                  data-wow-delay="0.2s"
                >
                  <h4 className>Get in Touch With Us</h4>
                  <p>
                    We really appreciate you taking the time to get in touch.!
                  </p>
                  <ul className="social-nav model-2">
                    <li>
                      <a
                        className="twitter"
                        onClick="window.open('https://twitter.com/thevrgroups')"
                        href="javascipt:void(0)"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="facebook"
                        onClick="window.open('https://www.facebook.com/THEVRGROUPS/')"
                        href="javascipt:void(0)"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="google-plus"
                        href="https://wa.me/919081888829"
                      >
                        <i
                          className="fa fa-whatsapp m-0"
                          style={{ fontSize: "28px" }}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        className="linkedin"
                        onClick="window.open('https://www.linkedin.com/')"
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.4s"
                >
                  <h4 className="f-title f_600 t_color f_size_18">Go to</h4>
                  <ul className="list-unstyled f_list">
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#service">Services</a>
                    </li>
                    <li>
                      <a href="#contacts">Contact</a>
                    </li>
                    <li>
                      <a href="#">Privacy &amp; Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div
                  className="f_widget social-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.8s"
                >
                  <h3 className="f-title f_600 t_color f_size_18">
                    Contact Us
                  </h3>
                  <ul className="list-unstyled f_list contact-list">
                    <li>
                      924, The Spire 2, Shital Park Circle, 150ft Ring Road,
                      Rajkot-360005, Gujarat, India.
                    </li>
                    <li>
                      <span>Email: </span>
                      <a href="mailto:welcome@thevrgroups.com">
                        welcome@thevrgroups.com
                      </a>
                    </li>
                    <li>
                      <span>Phone: </span>
                      <a href="tel:+919081888829">090818 88829</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_bg">
            <div className="footer_bg_one" />
            <div className="footer_bg_two" />
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-sm-12">
                <p className="mb-0 f_400">
                  © VR GROUPS 2022 All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer Area */}
      <Link to="#">
        <div className="go-top">
          <i className="fa fa-arrow-up text-white" />
        </div>
      </Link>
      {/* Message box */}
      <div className="message-box">
        <a href="#" className="msg-bd m1 d-none">
          Welcome to our site
        </a>
        <a href="#" className="msg-bd m2 d-none">
          How can i help ya
        </a>
        <p className="d-none">2</p>
        <img src="images/bin.png" alt="" srcSet className="msg-btn d-none" />
      </div>
    </>
  );
}
