import React, { useEffect, useState } from "react";
import "../asstes/style.css";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MyModal from "../Components/MyModal";
import { applyJob } from "../firebase/firebase";
import { uploadTaskFile } from "../firebase/firebase";
import { getDownloadURL } from "firebase/storage";
import logo from "../asstes/images/vrlogo.svg";

const SUPPORTED_FORMATS = ["application/pdf"];

const schema = yup.object({
  name: yup
    .string()
    .label("Name")
    .min(3, "name should be three characters long")
    .required()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name"),
  email: yup.string().email().label("Email").required(),
  contact: yup
    .string()
    .label("Contact")
    .required()
    .matches(/^[6-9]{1}[0-9]{9}$/, "Enter Valid Number"),
  file: yup.mixed().test("fileSize", "The file is required", (value) => {
    return value.length > 0;
  }),
});

export default function DetailJob() {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    window.scrollTo(0, 0);
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 1) {
        $("#header").addClass("is-sticky");
        $(".my-mb-nav").addClass("shadow");
      } else {
        $("#header").removeClass("is-sticky");
        $(".my-mb-nav").removeClass("shadow");
      }
    });
  }, []);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const location = useLocation();
  const item = location.state;
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/applyJob");
  };

  const onFileChange = (value) => {
    setFile(value);
  };
  const onSubmit = (data) => {
    //validate file
    if (file.type !== "application/pdf") {
      return setError("file", { message: "File Must Be PDF" });
    }
    setLoading(true);
    uploadTaskFile(file)
      .then((result) => {
        getDownloadURL(result.ref)
          .then((url) => {
            data.file = url;
            data.title = item.title;
            data.id = item.id;
            applyJob(data)
              .then((result) => {
                setShow(false);
                setLoading(false);
                setShowModal(true);
              })
              .catch((err) => {});
          })
          .catch((error) => {
            console.log("first err");
          });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <>
      <MyModal
        show={showModal}
        title="Submited"
        data="Your application has been submited to Us! we will response you soon Thank you!"
        handleClose={() => {
          navigate("/applyJob");
          reset();
        }}
        size="lg"
        centered
      />
      <header id="header">
        <div className="my-mb-nav p-0">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light d-flex justify-content-between align-item-center">
              <h5
                className="navbar-brand"
                style={{
                  cursor: "pointer",
                  fontSize: "1.8em",
                }}
                onClick={() => navigate("/")}
              >
                <img src={logo} alt="" className="navbar-logo mt-2" />
              </h5>
              <div>
                <div className="others-option d-flex align-item-center flex-1 ">
                  <div className="text-apply">
                    <a href="tel:+919081888829">
                      <button
                        style={{
                          cursor: "pointer",
                        }}
                        className="but button--webcoderskull button--border-thick button--text-medium d-lg-flex d-none"
                      >
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span className="d-lg-inline text-uppercase">
                          Call Us
                        </span>
                      </button>
                    </a>
                    <a href="tel:+919081888829">
                      <button
                        className="but button--webcoderskull button--border-thick button--text-medium d-lg-none d-block"
                        style={{
                          padding: ".6em .8em",
                          borderRadius: "50%",
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa fa-phone m-0"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.5em",
                            color: "blue",
                          }}
                        ></i>
                      </button>
                    </a>
                  </div>
                  <div style={{ marginLeft: "1em" }} className="text-apply">
                    <a href="https://wa.me/919081888829" target="_blank">
                      <button
                        style={{
                          cursor: "pointer",
                        }}
                        className="but button--web button--border-thick button--text-medium d-lg-flex d-none"
                      >
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        <span className="d-lg-inline text-uppercase">
                          Message Us
                        </span>
                      </button>
                    </a>
                    <a href="https://wa.me/919081888829" target="_blank">
                      <button
                        className="but button--webcoderskull button--border-thick button--text-medium d-lg-none d-block"
                        style={{
                          padding: ".6em .8em",
                          borderRadius: "50%",
                          color: "green",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa fa-whatsapp m-0"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.5em",
                            color: "green",
                          }}
                        ></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="p-4">
        <div
          className="container border mt-5"
          style={{ marginBottom: "5em", paddingBottom: "3em" }}
        >
          <div className="d-flex align-items-center justify-content-start p-3">
            <p
              className="job-title mt-4"
              onClick={handleGoBack}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>All Jobs
            </p>
          </div>

          <p
            className="job-title d-flex align-items-center justify-content-center mb-5"
            style={{ fontSize: "2em" }}
          >
            {item.title}
          </p>
          <hr />
          <div className="details p-lg-4">
            <h6>Qualifications</h6>
            <ul>
              {item.qualification?.map((qua, index) => (
                <li key={index}>{qua}</li>
              ))}
            </ul>
            <h6 className="mt-5">Skils</h6>
            <ul>
              {item.skils?.map((skil, index) => (
                <li key={index}>{skil}</li>
              ))}
            </ul>

            <h6 className="mt-5">Responsibilities</h6>
            <ul>
              {item.responsibilities?.map((res, index) => (
                <li key={index}>{res}</li>
              ))}
            </ul>
            <div className="d-flex align-items-center justify-content-center mt-5">
              <button
                className="but button--webc button--border-thick button--text-medium"
                onClick={() => setShow(true)}
              >
                Apply
                <i className="fa fa-share" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {show === true && (
        <div className="sidebar-form">
          <div className="sidebar-header">
            <h3>Apply Job</h3>
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ cursor: "pointer" }}
              onClick={() => {
                reset();
                setShow(false);
              }}
            ></i>
          </div>
          <div className="sidebar-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  {...register("name")}
                />
                {errors.name && (
                  <span className="text-error">{errors.name?.message}</span>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  {...register("email")}
                />
                {errors.email && (
                  <span className="text-error">{errors.email?.message}</span>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  maxLength={10}
                  {...register("contact")}
                />
                {errors.contact && (
                  <span className="text-error">{errors.contact?.message}</span>
                )}
              </div>
              <div className="upload-button">
                <div className="attach-file">
                  <div>
                    <div className="d-flex align-items-center justify-content-center">
                      <i className="fa fa-paperclip" aria-hidden="true"></i>
                      <h5 style={{ marginLeft: "1em" }}>
                        {file ? file.name : "Attach File"}
                      </h5>
                    </div>
                    <p className="m-0 text-center">Drag and Drop Your Resume</p>
                    <p className="m-0 text-center">Max Size Of File : 10 MB</p>

                    <h6>Click Here to Browse</h6>
                  </div>
                </div>
                <Controller
                  name="file"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, ...field } }) => (
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => {
                        onFileChange(e.target.files[0]);
                        onChange(e);
                      }}
                      {...field}
                    />
                  )}
                />
              </div>
              {errors.file && (
                <span className="text-error">{errors.file?.message}</span>
              )}
              <div className="d-flex align-items-center justify-content-center mt-2">
                <button
                  className="but button--webc button--border-thick button--text-medium"
                  type="submit"
                  style={{ cursor: "pointer" }}
                  disabled={loading}
                >
                  Send
                  {loading && (
                    <span className="spinner-border spinner-border-sm ms-2 me-2"></span>
                  )}
                  <i className="fa fa-share" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
